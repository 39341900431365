import React from 'react';
import {useNavigate} from "react-router-dom";

const ImageWithTextGrid = () => {
    const navigate = useNavigate();

    return (
        <div className="flex md:justify-center w-full mb-8 md:mb-16">
        <div className={"flex justify-center w-full"}>
            <div
                className="grid grid-cols-1 gap-0 reverse md:grid-cols-2 text-white items-center text-center space-x-4">


                {/* Image Section */}
                <div
                    className="md:hidden mb-12">
                    <div
                        className="pb-6 image-with-text__media image-with-text__media--adapt global-media-settings background-transparent media"
                    >
                        <img
                            src="https://cdn.whustle.com/vwchristmas2.gif"
                            alt="Apple Design Award"
                            className="w-full"
                            sizes="(min-width: 1200px) 550px, (min-width: 750px) calc((100vw - 130px) / 2), calc((100vw - 50px) / 2)"
                            fetchpriority="auto"
                        />
                    </div>
                </div>

                {/* Text Section */}
                <div className="items-center image-with-text__text-item">
                    <div
                        className="-mt-16 flex flex-col justify-center items-center h-full text-center content-container bg-transparent">
                        <h2 className="image-with-text__heading inline-richtext h1 mb-6 w-full">
                            <strong className="text-[30px] md:text-left text-center md:text-[40px] ">Vánoce jsou tu!🎄🎁️</strong>
                        </h2>
                        <div className="image-with-text__text rte body text-left w-full">
                            <ul className="text-[15px] md:text-[16px] leading-relaxed text-gray-300">
                                <li className={"list-none"}>✅ Doprava zdarma (2-5 dní) 🚚</li>
                                <li className={"list-none"}>✅ Nejlepší 1:1 repliky v Evropě</li>
                                <li className={"list-none"}>✅ Apple Care a záruka</li>
                                <li className={"list-none"}>✅ Vrácení peněz do 30 dnů zdarma 🔁</li>
                            </ul>
                        </div>
                    </div>
                </div>


                {/* Image Section */}
                <div
                    className="items-center md:block hidden mb-20 w-fit">
                    <div
                        className="pl-4 image-with-text__media image-with-text__media--adapt global-media-settings background-transparent media"
                    >
                        <div className="h-[400px] overflow-hidden">
                            <img
                                src="https://cdn.whustle.com/vwchristmas2.gif"
                                alt="Apple Design Award"
                                className="w-full h-full object-cover"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    );
};

export default ImageWithTextGrid;
