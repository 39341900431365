import React, { useState, useContext } from "react";
import { FiSearch, FiX } from "react-icons/fi";
import { ProductContext } from "../Contexts/ProductContext";
import { useNavigate } from "react-router-dom";

const SearchOverlay = ({ isOpen, onClose }) => {
    const { filterProductsByTitle } = useContext(ProductContext);
    const [searchTerm, setSearchTerm] = useState("");
    const [products, setProducts] = useState([]);
    const navigate = useNavigate();

    const handleSearchChange = (e) => {
        const value = e.target.value;
        setSearchTerm(value);
        setProducts(filterProductsByTitle(value));
    };

    const handleProductClick = (productHandle) => {
        onClose(); // Close the overlay
        setSearchTerm(""); // Clear the search term
        navigate(`/products/${productHandle}`, { replace: true });
    };

    return (
        <div
            className={`fixed inset-0 bg-white bg-opacity-45 z-50 transition-transform ${
                isOpen ? "translate-y-0" : "-translate-y-full"
            }`}
        >
            <div className="relative w-full max-w-3xl mx-auto bg-black p-6 rounded-lg mt-10">
                {/* Close button */}
                <button onClick={onClose} className="absolute top-4 right-4 text-white">
                    <FiX size={24} />
                </button>

                {/* Search input */}
                <div className="flex items-center border-b border-[#262626] pb-2">
                    <FiSearch className="text-white mr-2" />
                    <input
                        type="text"
                        placeholder="Zadejte název produktu..."
                        value={searchTerm}
                        onChange={handleSearchChange}
                        className="w-full p-2 text-white focus:outline-none bg-black"
                    />
                </div>

                {/* Search results */}
                <div className="mt-4">
                    {products.length === 0 && (
                        <p className="text-white">Nenalezeny žádné produkty.</p>
                    )}
                    {products.map((product) => (
                        <button
                            key={product.id}
                            className="flex justify-start items-center w-full p-2 border-b border-[#262626]"
                            onClick={() => handleProductClick(product.product_handle)}
                        >
                            <img
                                src={product.img}
                                alt={product.title}
                                className="w-16 h-16 object-cover rounded-md mr-4"
                            />
                            <div>
                                <h3 className="text-lg font-semibold text-white">
                                    {product.title}
                                </h3>
                                <p className="text-sm text-white text-left">{product.price} Kč</p>
                            </div>
                        </button>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default SearchOverlay;
