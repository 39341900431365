import React, {useEffect} from "react";
import {useCart} from "../../Contexts/CartContext";
import {useNavigate} from "react-router-dom"; // Import CartContext

const CartDrawer = ({onClose, size}) => {
    const {cartItems} = useCart(); // Access cart items
    const navigate = useNavigate();

    return (
        <div
            className={"mt-[0.8px] border-b border-r border-l border-[#262626]  fixed " + size + " right-0 md:right-4 z-40 flex flex-col bg-black text-white rounded-b-lg p-4 w-full max-w-md max-h-[75vh] overflow-y-auto shadow-lg transition-all duration-300"}>
            <div className="flex items-center justify-between mb-4">
                <h2 className="text-lg font-semibold">Položka přidána do košíku</h2>
                <button onClick={onClose} className="text-gray-500 hover:text-gray-700 transition">
                    ×
                </button>
            </div>

            <div className="mb-4">
                {cartItems.map((item, index) => (
                    <div key={index} className="mb-2 inline-flex">
                        <div>
                            <img className={"w-32"} src={item.img}/>
                        </div>
                        <div>
                            <p className="font-bold">{item.title}</p>
                            <p className="text-sm text-gray-500">Počet: {item.quantity}</p>
                            <p className="text-sm text-gray-500">Cena: {item.price} Kč</p>
                        </div>
                    </div>
                ))}
            </div>

            <div className="flex flex-col space-y-4">
                <button
                    onClick={() => {
                        onClose();
                        // Redirect to cart page
                        navigate("/cart");
                        // Redirect to cart page (or handle the navigation)
                    }}
                    className="w-full py-2 px-4 bg-blue-600 text-white rounded-full hover:bg-blue-500 transition"
                >
                    Zobrazit košík ({cartItems.length})
                </button>
                <button
                    onClick={onClose}
                    className="w-full py-2 px-4 bg-gray-100 text-gray-800 rounded-full hover:bg-gray-200 transition"
                >
                    Pokračovat v Nákupu
                </button>
            </div>
        </div>
    );
};

export default CartDrawer;
