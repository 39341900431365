import {useEffect} from "react";

function Download() {

    useEffect(() => {
        window.location = 'https://resellerheaven.pro/all-vendors.txt';
    }, []);

    return(<></>)
}

export default Download;
