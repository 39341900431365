import React, {useEffect, useState} from "react";
import CollapsibleItem from "../Components/Blocks/CollapsibleItem";
import {CiCircleInfo} from "react-icons/ci";
import {IoMdInformationCircleOutline, IoMdPricetag} from "react-icons/io";
import {IoPricetagOutline} from "react-icons/io5";
import {FiCheckSquare, FiPackage} from "react-icons/fi";
import ContactForm from "../Components/Blocks/ContactForm";

function Dotazy() {
    useEffect(() => {
        // Scroll to the top of the page
        window.scrollTo(0, 0);
    }, []); // Empty dependency array ensures this runs only once when the component mounts

    return (
        <div className="flex flex-col mt-44 w-full text-center items-center justify-center font-bold">
            <h1 className="mb-5 text-white text-[30px] md:text-[40px]">Nejčastější dotazy</h1>
            <div className="px-8 md:px-0 w-full md:w-1/2 my-4">
                <CollapsibleItem
                    icon={<IoMdInformationCircleOutline/>}
                    title={"Proč je to tak levné?"}
                    content={
                        <div>
                            <br/>
                            <p>Od roku 2021 se nám podařilo získat Apple produkty za extrémně <strong>nízké
                                ceny</strong>, což je výsledkem naší úzké <strong>spolupráce </strong>se
                                společností <strong>Apple</strong>. Díky výjimečným vztahům a přímé komunikaci máme
                                možnost pravidelně <strong>získávat testovací objednávky</strong> <strong>a speciální
                                    edice produktů</strong> za <strong>zvýhodněné ceny</strong>.
                            </p>
                            <br/>

                            <p>Apple
                                nás <strong>zahrnuje do svých pilotních projektů</strong> a<strong> testovacích
                                    programů</strong>, což nám umožňuje nabízet ty <strong>nejnovější </strong>modely
                                za <strong>bezkonkurenční ceny</strong>.
                            </p>
                            <br/>

                            <p>Tyto testovací produkty jsou <strong>zcela
                                nové a plně funkční</strong>, přičemž prošly přísnými <strong>kontrolami
                                kvality</strong>.
                                Naše dlouholetá spolupráce s Apple nám rovněž zaručuje přístup k exkluzivním nabídkám,
                                které
                                bychom jinak nemohli poskytovat.
                            </p>
                            <br/>

                            <p>Díky těmto výhodám máme jedinečnou pozici na trhu,
                                kterou rádi sdílíme s našimi zákazníky. Nakupujte u nás a využijte příležitosti pořídit
                                si
                                prémiové <strong>Apple produkty za výjimečné ceny!</strong>
                            </p>

                        </div>
                    }
                />
                <CollapsibleItem
                    icon={<IoPricetagOutline/>}
                    title={"Zabezpečená platba"}
                    content={
                        <p><br/>S<strong> AppleDrops </strong>můžete mít klid na duši s vědomím, že <strong>každá
                            transakce</strong>, kterou na naší stránce provedete, <strong>je chráněna pokročilou
                            šifrovací technologií </strong>pomocí nejvíce zabezpečené platební brány na světě - Stripe
                            LLC. Tím je zajištěno, že vaše <strong>informace </strong>o platbě budou <strong>přenášeny
                                bezpečně</strong> a nebudou se k nim moci dostat žádné neoprávněné osoby.
                        </p>
                    }
                />
                <CollapsibleItem
                    icon={<FiPackage/>}
                    title={"Kdy dorazí moje objednávka?"}
                    content={
                        <div>
                            <br/>
                            <p>Průměrné dodání objednávky trvá <strong>3-5 dní</strong>.
                            </p>
                            <br/>

                            <p>Jakmile provedete platbu,
                                snažíme se co <strong>nejrychleji odbavit</strong> vaši objednávku a odeslat ji k
                                doručení.
                                Po odeslání objednávky <strong>obdržíte trasovací číslo</strong> na váš e-mail.
                            </p>
                        </div>
                    }
                    />
                <CollapsibleItem
                    icon={<FiCheckSquare/>}
                    title={"Spokojenost zákazníků"}
                    content={
                    <p><br/>U každého z našich <strong>předešlých dropů </strong>si můžete <strong>přečíst ověřené recenze</strong> od našich zákazníku, kteří již u nás zakoupili. <strong>Zakládáme si na</strong> diskrétním jednání a <strong>vzájemné důvěře</strong>.
                    </p>
                    }
                />
            </div>

            {/* Contact Form */}
            <div className="md:mx-0 px-8 w-full md:w-5/6 mb-8">
                <ContactForm/>
            </div>
        </div>
    );
}

export default Dotazy;
