import React, {useState} from "react";

function ContactForm() {
    // State for form inputs
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: "",
        message: "",
    });

    // Handle input changes
    const handleInputChange = (e) => {
        const {id, value} = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [id]: value,
        }));
    };

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        // Here you can add your form submission logic (e.g., send data to an API)
        alert("Thank you for your inquiry! We'll get back to you soon.");
        // Reset form after submission
        setFormData({
            name: "",
            email: "",
            phone: "",
            message: "",
        });
    };
    return (<form className="md:px-60 mx-auto space-y-4 font-normal" onSubmit={handleSubmit}>
        <h1 className="mt-10 md:text-[40px] text-[30px] font-bold text-white">Máš dotaz?</h1>
        <p className="text-white md:text-[16px] text-[15px] font-normal pb-4">
            Neboj se a využij naši zákaznickou podporu. Jsme tu pro tebe 24 hodin denně a 7 dní v týdnu!
        </p>

        <div className={"flex flex-col-1 md:flex-col-2 space-x-2"}>
            <input
                type="text"
                id="name"
                placeholder="Jméno"
                value={formData.name}
                onChange={handleInputChange}
                className="bg-transparent border border-gray-300 text-white text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            />

            <input
                type="email"
                id="email"
                placeholder="E-Mail"
                value={formData.email}
                onChange={handleInputChange}
                className="bg-transparent border border-gray-300 text-white text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            />
        </div>

        <input
            type="text"
            id="phone"
            placeholder="Telefonní číslo"
            value={formData.phone}
            onChange={handleInputChange}
            className="bg-transparent border border-gray-300 text-white text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
        />

        <textarea
            id="message"
            rows="4"
            placeholder="Tvoje zpráva..."
            value={formData.message}
            onChange={handleInputChange}
            className="block p-2.5 w-full text-sm text-white bg-transparent rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
        ></textarea>

        <div className="w-full flex justify-center  items-center">
            <button
                type="submit"
                className="mt-2 w-full md:w-1/2 bg-blue-600 hover:bg-blue-500 text-white font-bold py-2 px-4 rounded-lg"
            >
                Odeslat
            </button>
        </div>

    </form>)
}

export default ContactForm;