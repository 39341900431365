import React from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

const DescriptionBlockLeft = ({title, img, description}) => {
    // Function to extract text from React element's `children`
    const extractTextFromReactElement = (element) => {
        if (React.isValidElement(element)) {
            return element.props.children || ''; // Extract `children` if valid React element
        }
        return element || ''; // Return as is if already a string
    };

    const descriptionText = extractTextFromReactElement(description);

    return (
        <div
            className="grid grid-cols-1 tablet:grid-cols-3 gap-0 reverse md:grid-cols-2 text-white text-left space-x-4 md:mt-1 mt-4">

            {/* Image Section */}
            <div
                className="md:hidden image-with-text__media-item image-with-text__media-item--small image-with-text__media-item--top">
                <div
                    className="pb-6 image-with-text__media image-with-text__media--adapt global-media-settings background-transparent media"
                >
                    <img
                        src={img}
                        alt={title}
                        className="w-full"
                        sizes="(min-width: 1200px) 550px, (min-width: 750px) calc((100vw - 130px) / 2), calc((100vw - 50px) / 2)"
                        fetchpriority="auto"
                    />
                </div>
            </div>

            {/* Image Section */}
            <div
                className="flex flex-col items-center justify-center md:block md:pr-8 mr-0 hidden image-with-text__media-item image-with-text__media-item--small image-with-text__media-item--top">
                <div
                    className="pl-4 image-with-text__media image-with-text__media--adapt global-media-settings background-transparent media"
                >
                    <img
                        src={img}
                        alt={title}
                        width="1500"
                        height="909"
                        sizes="(min-width: 1200px) 550px, (min-width: 750px) calc((100vw - 130px) / 2), calc((100vw - 50px) / 2)"
                        fetchpriority="auto"
                    />
                </div>
            </div>

            {/* Text Section */}
            <div className="flex flex-col items-center justify-center md:pb-0 pb-8">
                <div
                    className="text-left">
                    <h2 className="image-with-text__heading inline-richtext h1 mb-2">
                        <strong className={"text-[20px] md:text-[24px]"}>{title}</strong>
                    </h2>
                    <div
                        className="tracking-wider text-gray-300 image-with-text__text rte body text-[15px] md:text-[16px]">
                        {/* Render the extracted text as markdown with raw HTML */}
                        <ReactMarkdown
                            rehypePlugins={[rehypeRaw]}
                        >
                            {descriptionText}
                        </ReactMarkdown>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DescriptionBlockLeft;
