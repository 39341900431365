import React from 'react';
import { FaHeadset, FaUndo, FaTruck } from 'react-icons/fa';
import {BiChat, BiSolidTruck, BiUndo} from "react-icons/bi";
import {CgUndo} from "react-icons/cg";
import {BsArrowRepeat, BsChat, BsTruck} from "react-icons/bs";

const BenefitsBar = () => {
    const benefits = [
        {
            icon: <BsChat className="text-4xl" />, // 24/7 customer support icon
            text: "Zákaznická podpora 24/7",
        },
        {
            icon: <BsArrowRepeat className="text-4xl" />, // Refund icon
            text: "Vrácení peněz do 30 dnů zdarma",
        },
        {
            icon: <BsTruck className="text-4xl" />, // Satisfaction guarantee icon
            text: "100% garance spokojenosti",
        },
    ];

    return (
        <div className="-mt-16 flex justify-around items-center bg-black text-white w-full z-40 mb-8">
            {benefits.map((benefit, index) => (
                <div key={index} className="flex flex-col w-12 items-center text-center">
                    <div className="mb-2">{benefit.icon}</div>
                    <p className="text-sm font-semibold">{benefit.text}</p>
                </div>
            ))}
        </div>
    );
};

export default BenefitsBar;
