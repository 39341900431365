import {useLocation} from "react-router-dom";
import {useEffect} from "react";

const CollapsibleNav = ({title, isOpen, onClick, children, loc}) => {
    const location = useLocation(); // Get the current path

    useEffect(() => {
        console.log(location.pathname + " " + loc);
        console.log(location.pathname === loc);
    }, [loc, location])

    return (
        <div className="relative">
            {/* Main Navigation Button */}
            <button
                onClick={onClick} // Call the onClick handler to toggle state
                className={`${
                    (isOpen || loc === location.pathname) ? "md:ml-0 ml-2 underline text-white" : "md:ml-0 ml-2 text-white md:text-[#A4A4A4]"
                } hover:underline md:text-[14px]`}
            >
                {title}
            </button>

            {/* Render all children (SubNav) if open */}
            {isOpen && (
                <div className="absolute bg-black mt-2 rounded shadow-lg">
                    <ul className="mt-2 bg-black rounded shadow-lg py-2 px-4 text-left">
                        {children}
                    </ul>
                </div>
            )};
        </div>
    )
}
export default CollapsibleNav;
