import React, { createContext, useState, useEffect } from "react";

// Create a context
export const ReviewContext = createContext();

// Contexts provider component
export function ReviewProvider({ children }) {
    const [reviews, setReviews] = useState([]);

    // Fetch all reviews from the server on mount
    useEffect(() => {
        fetch("https://v2.whustle.com/reviews/3")
            .then(response => response.json())
            .then(data => setReviews(data))
            .catch(error => console.error("Error fetching reviews:", error));
    }, []);

    // Add a new review
    const addReview = async (newReview) => {
        const response = await fetch("https://api.resellerheaven.eu/reviews", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(newReview)
        });
        const addedReview = await response.json();
        setReviews([...reviews, { ...newReview, id: addedReview.reviewId }]);
    };

    // Modify an existing review
    const modifyReview = async (id, updatedReview) => {
        await fetch(`https://api.resellerheaven.eu/reviews/${id}`, {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(updatedReview)
        });
        setReviews(reviews.map(review => (review.id === id ? { ...updatedReview, id } : review)));
    };

    // Delete a review
    const deleteReview = async (id) => {
        await fetch(`https://api.resellerheaven.eu/reviews/${id}`, { method: "DELETE" });
        setReviews(reviews.filter(review => review.id !== id));
    };

    // Get reviews by product ID
    const getReviewsByProductId = (productId) => {
        return reviews.filter(review => review.product_id === productId);
    };

    const getReviewsByProductHandle = (productHandle) => {
        return reviews.filter(review => review.product_handle === productHandle);
    };

    return (
        <ReviewContext.Provider value={{ reviews, addReview, modifyReview, deleteReview, getReviewsByProductId, getReviewsByProductHandle }}>
            {children}
        </ReviewContext.Provider>
    );
}
