import React, { useEffect, useState } from 'react';

function CDN() {
    const [images, setImages] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [page, setPage] = useState('resellerheaven');
    const [filteredImages, setFilteredImages] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const imagesPerPage = 7;

    // Fetch images from the API
    useEffect(() => {
        fetch('https://api.resellerheaven.eu/cdn')
            .then(response => response.json())
            .then(data => {
                setImages(data.images);
                setFilteredImages(data.images);
            })
            .catch(error => console.error('Error fetching images:', error));
    }, []);

    // Filter images based on search term
    useEffect(() => {
        const results = images.filter(image =>
            image.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredImages(results);
        setCurrentPage(1); // Reset to the first page on search
    }, [searchTerm, images]);

    // Pagination logic
    const indexOfLastImage = currentPage * imagesPerPage;
    const indexOfFirstImage = indexOfLastImage - imagesPerPage;
    const currentImages = filteredImages.slice(indexOfFirstImage, indexOfLastImage);
    const totalPages = Math.ceil(filteredImages.length / imagesPerPage);

    return (
        <div className="w-full bg-gray-50 mt-28">
            <div className="p-8 min-h-screen md:w-2/3 mx-auto">
                <h1 className="text-2xl font-bold mb-6">Image Viewer</h1>
                <select
                    onChange={(e) => setPage((e.target.value + "").toLowerCase())} // Update the state with selected value
                    className="border py-2 px-1 rounded-lg my-2 focus:ring-2 focus:ring-blue-300 focus:border-transparent focus:outline-none "
                >
                    <option>ResellerHeaven</option>
                    <option>VendorsWorld</option>
                    <option>AppleDrops</option>
                </select>

                {/* Search Bar */}
                <div className="mb-6">
                    <input
                        type="text"
                        placeholder="Search images..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-300 focus:border-transparent"
                    />
                </div>

                <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
                    <table className="w-full text-sm text-left text-gray-500">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-100">
                        <tr>
                            <th scope="col" className="px-6 py-3">Thumbnail</th>
                            <th scope="col" className="px-6 py-3">Image Name</th>
                            <th scope="col" className="px-6 py-3">Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        {currentImages.map((imageName, index) => (
                            <tr key={index} className="bg-white border-b hover:bg-gray-50">
                                <td className="px-6 py-4">
                                    <img
                                        src={`https://api.resellerheaven.eu/cdn/${imageName}`}
                                        alt={imageName}
                                        className="w-16 h-16 object-cover rounded-lg"
                                    />
                                </td>
                                <td className="px-6 py-4 font-medium text-gray-900">{imageName}</td>
                                <td className="px-6 py-4">
                                    <button
                                        onClick={() =>
                                            window.open(
                                                `https://api.${page}.eu/cdn/${imageName}`,
                                                '_blank'
                                            )
                                        }
                                        className="text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2"
                                    >
                                        View
                                    </button>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>

                {/* Pagination */}
                <div className="flex justify-between items-center mt-4">
                    <button
                        onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                        disabled={currentPage === 1}
                        className={`px-4 py-2 font-medium text-sm ${
                            currentPage === 1
                                ? 'bg-gray-300 text-gray-600 cursor-not-allowed'
                                : 'bg-blue-600 text-white hover:bg-blue-700'
                        } rounded-lg`}
                    >
                        Previous
                    </button>
                    <span className="text-gray-700 font-medium">
            Page {currentPage} of {totalPages}
          </span>
                    <button
                        onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                        disabled={currentPage === totalPages}
                        className={`px-4 py-2 font-medium text-sm ${
                            currentPage === totalPages
                                ? 'bg-gray-300 text-gray-600 cursor-not-allowed'
                                : 'bg-blue-600 text-white hover:bg-blue-700'
                        } rounded-lg`}
                    >
                        Next
                    </button>
                </div>
            </div>
        </div>
    );
}

export default CDN;
