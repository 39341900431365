import {useState} from "react";

function UploadPage() {
    const [token, setToken] = useState('');
    const [file, setFile] = useState(null);
    const [message, setMessage] = useState('');

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleTokenChange = (e) => {
        setToken(e.target.value);
    };

    const handleUpload = async (e) => {
        e.preventDefault();

        if (!file || !token) {
            setMessage('Please provide both an image and an authentication token');
            return;
        }

        const formData = new FormData();
        formData.append('image', file);

        try {
            const response = await fetch('https://api.resellerheaven.eu/upload?token=' + encodeURIComponent(token), {
                method: 'POST',
                body: formData,
            });

            if (response.ok) {
                const data = await response.json();
                setMessage(data.message || 'Image uploaded successfully!');
            } else {
                const errorData = await response.json();
                setMessage(errorData.error || 'Failed to upload image');
            }
        } catch (error) {
            setMessage('Error uploading image: ' + error.message);
        }
    };

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-black text-white p-4">
            <div className="bg-gray-900 p-6 rounded-lg shadow-lg w-full max-w-md">
                <h2 className="text-2xl font-bold mb-4 text-center">Upload Image</h2>
                <form onSubmit={handleUpload} className="space-y-4">
                    <input
                        type="text"
                        placeholder="Enter Auth Token"
                        value={token}
                        onChange={handleTokenChange}
                        className="bg-gray-800 w-full p-2 border border-gray-800 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                    <input
                        type="file"
                        onChange={handleFileChange}
                        className="w-full p-2 border border-gray-800 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                    <button
                        type="submit"
                        className="w-full bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                    >
                        Upload
                    </button>
                </form>
                {message && <p className="mt-4 text-center text-gray-700">{message}</p>}
            </div>
        </div>
    );
}

export default UploadPage;