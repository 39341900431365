import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ProductCard from "../Components/Product/ProductCard";
import CategoryPreview from "../Components/Blocks/CategoryPreview";
import { ProductContext } from "../Contexts/ProductContext";
import {ReviewContext} from "../Contexts/ReviewContext";

const ProductCatalogue = () => {
    const location = useLocation();
    const [collections, setCollections] = useState([]);
    const [products, setProducts] = useState([]);
    const collectionName = decodeURIComponent(location.pathname.split("/")[2]) || "all"; // Get collection ID from the URL
    const [collectionId, setCollectionId] = useState(8);
    const [description, setDescription] = useState("");
    const [title, setTitle] = useState("");
    const { getReviewsByProductHandle } = useContext(ReviewContext);
    const reviews = getReviewsByProductHandle();
    const calculateAverageRating = (productHandle) => {
        const reviews = getReviewsByProductHandle(productHandle);
        if (reviews.length === 0) return 0;
        const totalRating = reviews.reduce((acc, review) => acc + review.rating, 0);
        return totalRating / reviews.length;
    };

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
        const checkMobile = () => setIsMobile(window.innerWidth < 768);
        checkMobile();
        window.addEventListener("resize", checkMobile);
        return () => window.removeEventListener("resize", checkMobile);
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);

        // Reset state on navigation to the same component with a different path
        setCollections([]);
        setProducts([]);
        setCollectionId(null);
        setDescription("");
        setTitle("");

        // Fetch collections and products
        const fetchCollectionsAndProducts = async () => {
            try {
                // Replace these API endpoints with your actual endpoints
                const collectionMainsResponse = await fetch(`https://v2.whustle.com/api/collectionsv2/3`, {
                    method: "GET",
                    headers: {
                        "Authorization": `Bearer ${localStorage.getItem("token")}`,
                        "Content-Type": "application/json",
                    },
                });
                const collectionMains = await collectionMainsResponse.json();

                const filteredItem = collectionMains.find(item => item.handle === collectionName);
                if (filteredItem) {
                    setCollectionId(filteredItem.id);
                    setTitle(filteredItem.title);
                    setDescription(filteredItem.description);

                    const collectionsResponse = await fetch(
                        `https://v2.whustle.com/api/collections?collection_id=` + filteredItem.id
                    );
                    const productsResponse = await fetch(
                        `https://v2.whustle.com/api/products?collection_id=` + filteredItem.id
                    );

                    const collectionsData = await collectionsResponse.json();
                    const productsData = await productsResponse.json();

                    setCollections(collectionsData);
                    setProducts(productsData);
                }
            } catch (error) {
                console.error("Error fetching collections or products:", error);
            }
        };

        fetchCollectionsAndProducts();
    }, [collectionName]); // React to changes in collectionName

    const renderCollections = (collection) => {
        if (!collection.children || collection.children.length === 0) {
            // Render a standalone collection with no children
            return (
                <CategoryPreview
                    title={collection.title}
                    key={collection.id}
                    handle={collection.handle}
                />
            );
        }

        // Render a parent collection and its children
        return (
            <div key={collection.id} className="mb-6">
                <CategoryPreview title={collection.title} handle={collection.handle} />
                <div className="pl-4 border-l border-gray-300 mt-2">
                    {collection.children.map(renderCollections)}
                </div>
            </div>
        );
    };

    return (
        <div key={location.key} className="w-full mt-44 md:px-0 px-4">
            <div className="w-full md:w-3/5 mx-auto">
                <div className="text-left items-start mb-8 px-4">
                    <h1 className="text-[#A4A4A4] text-[24px] md:text-[40px] font-semibold">
                        {collectionName === "all" ? "All Products" : `${title}`}
                    </h1>
                    <p className={"mt-2 text-gray-300"}>{description}</p>
                </div>

                {/* Render collections if available */}
                {collections.length > 0 && (
                    <div className="mb-8">
                        <h2 className="text-xl font-semibold mb-4">Collections</h2>
                        <div>{collections.map(renderCollections)}</div>
                    </div>
                )}


                {/* Render products if available */}
                {collections.length === 0 && (
                    <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
                        {products.map((product) => (
                            <ProductCard
                                key={product.id}
                                product={product}
                                averageRating={calculateAverageRating(product.product_handle)}
                                reviewsCount={getReviewsByProductHandle(product.product_handle).length}
                                shortReview={isMobile}
                            />
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default ProductCatalogue;
