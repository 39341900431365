import {useNavigate} from "react-router-dom";

const SubNav = ({ title, location, onClick }) => {
    const navigate = useNavigate();

    return (
        <li className="py-1">
            <button
                onClick={() => navigate(location)}
                className="text-[#A4A4A4] text-[14px] whitespace-nowrap hover:text-white"
            >
                {title}
            </button>
        </li>
    );
};

export default SubNav;
